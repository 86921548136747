.project-stats {
  &.tile {
    padding: 40px;
  }


  &__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__status h3.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__progress {
    margin-bottom: 20px;

    .progress {
      background: var(--color-light-grey);
      overflow: unset;
      border-radius: 100px;
    }

    .progress-bar {
      background: var(--color-light-orange);
      border-radius: 100px;
    }

    .percentage {
      display: block;
      text-align: right;
      font-size: 24px;
      line-height: 1.26;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 7px;
    }
  }


  &__list {
    margin-bottom: 24px;

    @media (max-width: 1399px) {
      font-size: 16px;
    }

    .value {
      white-space: nowrap;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 28px 16px;
    font-size: 16px;
    line-height: 1.26;

    dt {
      font-weight: 400;
      color: var(--color-grey);
    }

    dd {
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.stats-block {
  position: relative;
  padding: 16px 24px;
  text-align: center;
  border: 1px solid var(--color-separator);
  flex-basis: calc(50% - 8px);
  border-radius: 16px;

  &.active {
    border-color: var(--color-light-orange);

    .stats-block__badge {
      border-color: var(--color-light-orange);
      background-color: var(--color-text-white);
      color: var(--color-light-orange);
    }

    .separator {
      background-color: var(--color-light-orange);
    }
  }

  &.completed {

    .stats-block__badge {
      background-color: var(--color-medium-grey);
      border-color: var(--color-medium-grey);
    }
  }

  dt {
    margin-bottom: 6px;
  }

  &--double {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 21px 24px 16px;

    .separator {
      height: 100%;
      width: 1px;
      margin: 0 24px;
      background-color: var(--color-separator);
    }

    & > div:not(.separator) {
      flex-grow: 1;
    }
  }

  &__badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 16px;
    top: -12px;
    padding: 4px 12px;
    border-radius: 100px;
    line-height: 1.26;
    font-weight: 600;
    letter-spacing: normal;
    border: 1px solid var(--color-light-grey);
    color: var(--color-text-black);
    background-color: var(--color-light-grey);

    &:empty {
      display: none;
    }
  }
}

@media (max-width: 1399px) {
  .project-stats__info {
    font-size: 14px;
  }

  .stats-block {
    padding: 16px;
  }

  .stats-block--double {
    padding: 19px 16px 16px;
  }
}

@media (max-width: 1199px) {
  .project-stats__status {
    margin-bottom: 16px;
  }
}
