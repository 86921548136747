.project-tag {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    border-radius: 100px;
    backdrop-filter: blur(00px);
    background-color: var(--color-light-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 126%;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.project-tag--status {
    color: var(--color-text-white);
    font-weight: 600;
}

.project-tag__image {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

@media (max-width: 1199px) {
    .project-tag.badge {
        font-size: 12px;
        letter-spacing: 1px;
    }
}

.project-tag.coming-soon {
    background: linear-gradient(91.53deg, #FF833D 1.3%, #FF9E67 98.7%);
}

.project-tag.registration-open {
    background: linear-gradient(91.53deg, #0B96B5 1.3%, #02C88D 98.7%);
}

.project-tag.registration-closed {
    background: linear-gradient(91.53deg, #0B96B5 1.3%, #48505C 98.7%);
}

.project-tag.guaranteed-live {
    background: linear-gradient(42.26deg, #EF8BFF 33.95%, #9946fe 138.93%);
}

.project-tag.fcfs-coming {
    background: linear-gradient(42.26deg, #8729cf 33.95%, #160034 138.93%);
}

.project-tag.fcfs-live {
    background: linear-gradient(90deg, #FE4646 0%, #FF7272 100%);
}

.project-tag.closed {
    background: linear-gradient(90deg, #444B56 0%, #5F6978 100%);
}
