@mixin blurred-dark-background($length, $position: relative) {
  @supports (backdrop-filter: blur($length)) {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur($length);
  }

  @supports not (backdrop-filter: blur($length)) {
    position: $position;
    background-color: rgba(43, 43, 43, 0.9);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      filter: blur($length);
      background-color: inherit;
      z-index: -1;
    }
  }
}

@mixin gap($pixels) {
  @supports (gap: $pixels) {
    gap: $pixels;
  }

  @supports not (gap: $pixels) {
    & > * + * {
      margin-left: $pixels;
    }
  }
}
