.round-button {
  position: relative;
}

.round-button, .btn.round-button {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--button-bg);
  color: var(--button-color);
  border: 1px solid var(--button-border);
  border-radius: 20px;
  padding: 16px 20px;

  &:hover:not(:disabled) {
    background-color: var(--button-hover-bg, var(--button-bg));
    color: var(--button-hover-color, var(--button-color));
    border-color: var(--button-hover-border, var(--button-border));
  }

  &:hover:disabled {
    color: var(--button-color);
  }
}

.round-button svg {
  height: 20px;
  margin-left: 5px;

  path {
    fill: currentColor;
  }
}

.round-button .link-arrow {
  height: 1.2em;
  width: 1.2em;
}

.round-button .link-arrow path {
  fill: currentColor;
  transition: .15s;
}

.round-button a, .btn.round-button a {
  color: currentColor;
  text-decoration: none;
}

.round-button a:hover, .btn.round-button a:hover {
  color: currentColor;
  text-decoration: none;
}

.round-button.dark {
  --button-bg: var(--color-text-black);
  --button-color: var(--color-text-white);
  --button-border: var(--color-text-black);
  --button-hover-bg: var(--color-text-white);
  --button-hover-color: var(--color-text-black);
}

.round-button.transparent {
  --button-bg: rgba(255, 255, 255, 0.08);
  --button-color: var(--color-text-black);
  --button-border: var(--color-text-white);
  --button-hover-bg: var(--color-text-black);
  --button-hover-border: var(--color-text-black);
  --button-hover-color: var(--color-text-white);
  backdrop-filter: blur(20px);
}

.round-button.light {
  --button-bg: var(--color-text-white);
  --button-color: var(--color-text-black);
  --button-border: var(--color-text-black);
  --button-hover-color: var(--color-text-white);
  --button-hover-bg: var(--color-text-black);
}

.round-button.large {
  line-height: 24px;
  padding-left: 40px;
  padding-right: 40px;
}

.round-button.small {
  line-height: 18px;
  padding-left: 16px;
  padding-right: 16px;
}

.round-button.wide {
  padding-left: 66px;
  padding-right: 66px;
}

@media (max-width: 1399px) {
  .round-button, .btn.round-button {
    font-size: 14px;
    line-height: 18px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .round-button.large {
    line-height: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .round-button.wide {
    padding-left: 55px;
    padding-right: 55px;
  }

  .round-button.small {
    line-height: 16px;
  }
}

.btn.round-button:disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

.round-button.round-button--with-badge:disabled {
  opacity: 1;
}

.round-button__badge {
  position: absolute;
  top: -20px;
  left: 8px;
  font-size: 12px;
  line-height: 1.26;
  color: var(--color-background);
  padding: 6px 12px;
  background-color: var(--color-text-white);
  border: 2px solid var(--color-background);
  border-radius: 20px;
  text-transform: none;
  transform: rotate(-3.93deg);
}

.round-button * + .loader {
  margin-left: 6px;
}

.round-button .loader:not(:last-child) {
  margin-right: 6px;
}
