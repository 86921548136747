.timezone-switcher {
  padding: 4px;

  input {
    appearance: none;
  }

  .timezone-option {
    position: relative;
    display: inline-block;
    padding: 9px 6px;
    min-width: 70px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.15s linear;
    z-index: 0;

    &.active {
      color: var(--color-light-orange);
      font-weight: 700;
    }

    &:first-child {
      &:before {
        position: absolute;
        content: '';
        left: calc(100% + 8px);
        top: 100%;
        width: calc(100% - 8px);
        height: 2px;
        border-radius: 50px;
        background: var(--color-light-orange);
        transition: 0.15s linear;
        z-index: -1;
      }

      &.active:before {
        left: 4px;
      }
    }
  }

  label + label {
    margin-left: 4px;
  }
}
