.wrong-network-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.embedded {
    padding: 24px;
    border: 1px solid var(--color-separator);
    border-radius: 24px;
  }

  &__icon {
    margin-right: 12px;
    height: 40px;
    width: auto;
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 1px;
    margin-right: 12px;
  }

  &__button {
    margin-left: auto;
  }
}
