@import "src/styles/mixins";

.buy-zkst-dropdown {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.buy-zkst-dropdown {
  .dropdown-toggle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--color-text-black);
    background: var(--color-text-white);
    border-color: var(--color-text-black);
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 2px 30px;
  }

  &.show .btn-primary.dropdown-toggle,
  .btn-primary.dropdown-toggle:hover,
  .btn-primary.dropdown-toggle:focus,
  .btn-primary.dropdown-toggle:active {
    border-color: var(--color-text-black);
    background: var(--color-text-white);
    color: var(--color-text-black);
  }

  .dropdown-menu {
    margin-top: 8px;
    min-width: 100%;
    overflow: hidden;
    border-color: var(--color-text-black);
    color: var(--color-text-black);
    background: var(--color-text-white);
    border-radius: 16px;
    z-index: 2;
  }

  &__item {
    img {
      height: 32px;
      width: 32px;
      margin-right: 10px;
    }

    &.dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 700;
      color: currentColor;
      padding: 0.5rem 1rem;

      &:hover,
      &:active,
      &.active {
        background-color: transparent;
        color: var(--color-light-orange);
      }
    }
  }
}
