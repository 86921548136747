@import "src/styles/mixins";

.tooltip-wrapper {
    cursor: pointer;

    svg path {
        fill: var(--color-grey);
    }
}

.common-tooltip .tooltip-inner {
    background-color: rgba(255, 255, 255, 0.4);
    color: var(--color-text-black);
    border: 1px solid var(--color-medium-grey);
    backdrop-filter: blur(60px);
    border-radius: 20px;
    padding: 20px;
    max-width: 450px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
}

.common-tooltip.tooltip.show {
    opacity: 1;
}
