.form-fill-statusbar {
  display: flex;
  align-items: center;
  gap: 30px;

  .progress {
    flex-grow: 1;
    background: var(--color-light-grey);
    overflow: unset;
    border-radius: 100px;
  }

  .progress-bar {
    background: var(--color-light-orange);
    border-radius: 100px;
  }

  &__value {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.5px;
  }
}