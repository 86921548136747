.tiers-table {
    color: var(--color-text-white);
    border-radius: 32px;
    background-color: rgba(11, 9, 9, 0.1);
    border: 1px solid var(--color-grey);
    backdrop-filter: blur(200px);
    overflow: hidden;
}

.tiers-table__head {
    color: var(--color-medium-grey);
    display: grid;
    grid-template-columns: 1.15fr repeat(5, 1fr);
    background-color: #1D1B1B;
}

.tiers-table__body {
    display: grid;
    grid-template-columns: 1.15fr repeat(5, 1fr);
}

.tiers-table__col {
    grid-column: span 1;
    grid-row: span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    position: relative;
}

.tiers-table__head .tiers-table__col {
    height: 72px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.tiers-table__body .tiers-table__col {
    border: 1px solid var(--color-grey);
    font-size: 24px;
    line-height: 1.4;
    margin: -1px;
    min-height: 100px;
}

.tiers-table__col.rowspan-1-4 {
    grid-row: 1 / span 4;
    grid-column: 6 / span 1;
}
.tiers-table__col.rowspan-2-4 {
    grid-row: 2 / span 4;
    grid-column: 1 / span 1;
}
.tiers-table__col.colspan-2-5 {
    grid-column: 2 / span 5;
}

.tiers-table__col .tooltip-wrapper {
    position: absolute;
    display: flex;
    width: 16px;
    height: 16px;
    z-index: 2;
}

.tiers-table__col--access {
    padding-left: 33px;
    justify-content: flex-start;
}

.tiers-table__col--access .tooltip-wrapper {
    top: 13px;
    right: 13px;
}

.tiers-table__col--with-image {
    line-height: 1.5;
    letter-spacing: 1px;
}

.tiers-table__col--with-image :is(.dynamic-image, img, svg) {
    margin-right: 12px;
}

.tiers-table__col--with-image:not(.tiers-table__col--tickets) :is(.dynamic-image, img, svg) {
    width: 60px;
    height: 60px;
}

.tiers-table__col--tier {
    justify-content: flex-start;
}

.tiers-table__col--tier :is(img, .dynamic-image) {
    margin-left: 13px;
}

.tiers-multiplier--rotate {
    font-size: 32px;
    transform: rotate(-25deg);
}

.tiers-table__col--surprise span {
    font-size: 12px;
    line-height: 1.5;
}

.tiers-table__col--surprise :is(.dynamic-image, img) {
    width: 60px;
    height: 60px;
    margin-right: 8px;
}

.tiers-table__col--surprise .orange-text {
    font-size: 16px;
}

.tiers-table__col--tickets {
    justify-content: flex-start;
    font-weight: 600;
}

.tiers-table__col--tickets :is(.dynamic-image, svg, img) {
    margin-left: 24px;
}

.tiers-table__col.tiers-table__col--cooldown {
    flex-wrap: wrap;
    align-content: center;
    text-align: center;
}

.tiers-table__col--cooldown :is(img, .dynamic-image) {
    margin-bottom: 8px;
}

.tiers-table__col--cooldown .text {
    flex-basis: 100%;
    font-size: 16px;
}

.tiers-table__col--non-cooldown {
    padding: 13px 6px;
}

.tiers-table__col--non-cooldown .text {
    font-size: 12px;
    line-height: 1.4;
}

.tiers-table__col--non-cooldown  :is(img, .dynamic-image) {
    margin-right: 8px;
    width: 60px;
}

.tiers-table__col--leaders .text {
    display: block;
    max-width: 615px;
    margin: 0 auto;
}

.tiers-table__col--highlight {
    background-color: rgba(29, 27, 27, 0.5);
}

.tiers-table .orange-text {
    font-weight: 600;
}

.zk-power-requirement {
    right: 13px;
    top: 13px;
}

.cooldown-period {
    right: 12px;
    top: calc(50% - 8px);
}

.tiers-tooltip {
    max-width: 392px;
}

.tiers-tooltip.common-tooltip .tooltip-inner {
    border-radius: 16px;
}

.tiers-tooltip__title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 1.12;
    font-weight: 600;
    margin-bottom: 16px;
}

.tiers-tooltip__title img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.tiers-tooltip__content p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
}

.tiers-tooltip__button {
    width: 100%;
    margin-top: 24px;
}

.tiers-tooltip__widget {
    background: rgba(11, 11, 15, 0.4);
    border: 1px solid #2D3449;
    backdrop-filter: blur(80px);
    border-radius: 16px;
    padding: 16px;
    margin-top: 12px;
}

.tiers-tooltip__widget .orange-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.5px;
}

.tiers-tooltip__expression {
    margin-bottom: 8px;
}

.tiers-tooltip__expression b {
    margin: 0 6px;
}

.tiers-tooltip__expression span {
    width: 32px;
    height: 32px;
}
