.footer {
    padding: 3rem 0;
    background-color: var(--color-text-black);
    z-index: 1;
    margin-top: 100px;
    color: var(--color-text-white);

    svg path {
        transition: .2s linear;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    &__logo {
        a {
            display: block;
            width: 207px;
        }

        img {
            width: 100%;
            height: 43px;
        }
    }



    &__list-item,
    &__list-title {
        font-size: 16px;
        margin-bottom: 16px;
    }

    &__list-title {
        font-weight: 400;
        line-height: 1.26;
        letter-spacing: 1px;
        color: var(--color-grey);
    }

    &__list-item {
        line-height: 20px;
        letter-spacing: 0.3px;

        a {
            color: var(--color-text-white);
        }
    }

    & &__socials {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        flex-wrap: wrap;

        @supports (gap: 16px) {
            gap: 16px;
        }

        @supports not (gap: 16px) {
            & > * + * {
                margin-left: 16px;
            }
        }
    }

    &__socials {
        a:hover svg path {
            fill: var(--color-light-orange);
        }
    }

    &__socials-item {

        svg {
            width: 32px;
            height: 32px;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 18px;
    }

    .btn.round-button {
        margin: 0;
    }

    @media (max-width: 1199px) {
        &__logo {
            margin-bottom: 40px;
        }

        &__col--socials {
            margin-top: 40px;
        }

        &__col--socials .footer__col-content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @supports (gap: 40px) {
                gap: 40px;
            }

            @supports not (gap: 40px) {
                & > * + * {
                    margin-left: 40px;
                }
            }
        }

        & &__socials {
            margin-bottom: 0;
        }

        &__col--socials .footer__title {
            margin-bottom: 0;
        }
    }
}

.row.copyrights-row {
    margin-top: 40px;
}

.footer-copyrights {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 126%;
    letter-spacing: 1px;
    color: var(--color-text-white-transparantize);
    display: flex;
    justify-content: center;
    align-items: center;
}

.token-address {
    padding: 11px 12px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 50px;

    &, .copiable {
        display: inline-flex;
        align-items: center;

        @supports (gap: 10px) {
            gap: 10px;
        }

        @supports not (gap: 10px) {
            & > * + * {
                margin-left: 10px;
            }
        }
    }

    &__address {
        font-weight: 600;
        letter-spacing: 1px;
    }
}
