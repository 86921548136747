.kyc-badge {
  &__wallet {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;

    .copiable {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__status {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.26;
  }

  &__message {
    margin: 0;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  &__action {
    flex-shrink: 0;
  }
}

.kyc-approved-mark {
  display: flex;
  align-items: center;
  border-radius: 26px;
  padding: 20px 40px;
  background-color: var(--color-text-white);
  border: 1px solid var(--color-text-black);
}
