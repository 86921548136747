.portfolio-item.tile {
    padding: 40px 40px 12px;
    position: relative;
}

.portfolio-item__main {
    display: grid;
    grid-template-columns: var(--portfolio-columns);
    padding: 0 0 24px;
    grid-gap: 18px;
}

.portfolio-item__col {
    display: flex;
    align-items: center;
}

.portfolio-item__col span:empty {
    position: relative;
    margin-left: 30%;
}

.portfolio-item__col span:empty:before {
    content: '–';
}

@supports (gap: 16px) {
    .portfolio-item__col,
    .portfolio-item__additions {
        gap: 16px;
    }
}

@supports not (gap: 16px) {
    .portfolio-item__col > * + *,
    .portfolio-item__additions > * + * {
        margin-left: 16px;
    }
}

.portfolio-item__name {
    margin-bottom: 0;
}

.portfolio-item__name a {
    text-decoration: none;
}

.portfolio-item__logo {
    position: relative;
    width: 90px;
    height: 90px;
    margin-right: 8px;
    text-align: center;
    flex-shrink: 0;
}

.portfolio-item__logo-image {
    width: 100%;
    height: 100%;
}

.portfolio-item__network-image {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.portfolio-item__col h4 {
    font-size: 32px;
    font-weight: 600;
}

.portfolio-item__col span {
    text-align: center;
}

.portfolio-item__col .round-button {
    white-space: nowrap;
}

.round-button.portfolio-item__toggle {
    padding-left: 13px;
    padding-right: 13px;
}

.portfolio-item__toggle svg {
    padding: 0;
    width: 15px;
    margin-left: 12px;
    transition: .2s linear;
    transform-origin: center;
}

.portfolio-item__toggle.active svg {
    transform: rotate(180deg);
}

.portfolio-item__collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.portfolio-item__collapse p {
    margin-right: 56px;
}

.portfolio-item__additions {
    display: flex;
    margin-bottom: 0;
}

.portfolio-item__additions div {
    border: 1px solid var(--color-separator);
    border-radius: 20px;
    text-align: center;
    padding: 16px 20px;
    width: 248px;
    line-height: 1.26;
    letter-spacing: 1px;
}

.portfolio-item__additions dt {
    font-weight: 400;
    color: var(--color-grey);
    margin-bottom: 4px;
}

.portfolio-item__additions dd {
    font-weight: 400;
}

.portfolio-item__footer {
    text-align: center;
    padding: 24px 0 12px;
    border-top: 1px solid var(--color-separator);
}

.portfolio-item__schedule svg {
    margin-right: 12px;
}

.portfolio-item__col--wrong-network {
    display: grid;
    padding-left: 32px;
    grid-column-start: 2;
    grid-column-end: 7;
    font-size: 18px;
}
