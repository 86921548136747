.marketing-page {
    position: relative;
    padding-top: 60px;
}

.marketing-page.page h1.title {
    margin-bottom: 24px;
}

.form-fill-response {
    max-width: 604px;
    margin: 48px auto;
}

.form-fill-response h2 {
    margin-bottom: 24px;
}

.form-fill-response p {
    margin-bottom: 32px;
}

.marketing-page-form {
    height: calc(100vh - 326px);
    max-width: 624px;
    margin: 48px auto;
}

.marketing-page-form.tile:has(.custom-scroll) {
    padding: 40px 8px 40px 40px;
    border-radius: 32px;
}

.marketing-page-form__inner {
    max-height: 100%;
}

.marketing-page-form__inner.custom-scroll {
    padding-right: 14px;
}

.marketing-page-form h2.title {
    margin-bottom: 24px;
}

.marketing-page-form h4.title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.12;
}

.marketing-page-form .row + .row {
    margin-top: 32px;
}

.marketing-page-form .form-group {
    margin-bottom: 0;
}

.marketing-page-form .form-group:not(:last-child) {
    margin-bottom: 24px;
}

.marketing-page-form .form-group.form-group--radio:not(:last-child) {
    margin-bottom: 16px;
}

.marketing-page-form__submit-row.row {
    margin-top: 32px;
}

.marketing-page-form__submit-row .round-button {
    width: 100%;
}

.marketing-page-form__submit-row .loader {
    vertical-align: middle;
}

.marketing-page__text p,
.marketing-page__text li {
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
}

.marketing-page__text ol[type="a"] {
    counter-reset: item;
    list-style-type: none;
}

.marketing-page__text ol[type="a"] > li:before {
    counter-increment: item;
    content: counter(item, lower-alpha) ") ";
}

.marketing-page__text ol.both-side-braces-list[type="a"] > li:before {
    content: "(" counter(item, lower-alpha) ") ";
}

.marketing-page__text a {
    color: var(--color-text-violet);
    text-decoration: none;
}

.marketing-page__text a:hover {
    color: var(--color-text-black);
}

.marketing-page__text h2.title {
    margin-top: 40px;
    margin-bottom: 16px;
}

.marketing-page__text h3.title {
    margin-top: 32px;
    margin-bottom: 16px;
}

.marketing-page__text h3.title em {
    color: var(--color-text-white-transparantize);
}
