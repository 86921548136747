.saft-modal__dialog {
    min-height: unset;
    height: 85vh;
}

.saft-modal .close-button {
    appearance: none;
    position: absolute;
    right: 20px;
    top: 20px;
    outline: none;
    border: none;
    background: none;
}

.saft-modal .close-button svg path {
    fill: currentColor;
}

.saft-modal .close-button:hover {
    color: var(--color-text-red);
}

.modal-content.saft-modal__content {
    padding: 32px;
}

.modal-header.saft-modal__header {
    justify-content: flex-start;
    border: none;
    margin-bottom: 8px;
}

.saft-modal__project-logo {
    width: 140px;
    height: 140px;
    margin-right: 24px;
}

@media (max-height: 850px) {
    .saft-modal__project-logo {
        width: 95px;
        height: 95px;
    }
}

@media (max-width: 1199px) {
    .saft-modal__body h2.title {
        font-size: 32px;
    }
}

.saft-modal__project-name {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.26;
    margin: 0;
}

.saft-content {
    margin-top: 32px;
    height: 32vh;
    padding-right: 20px;
    max-height: 583px;
}

.saft-content ol {
    padding-left: 3rem;
}

.saft-content h2 {
    font-size: 28px;
    margin-bottom: 18px;
}

.modal-footer.saft-modal__footer {
    border: none;
    flex-direction: column;
}

.saft-modal__footer .form-group {
    margin: 0;
}

.saft-modal__footer > div {
    margin: 10px 0;
}

.saft-modal__footer .round-button.transparent {
    background: none;
    text-decoration: underline;
    text-transform: none;
    backdrop-filter: none;
    border-color: transparent;
}

.round-button.saft-modal__continue {
    width: 428px;
}
