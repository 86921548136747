.ido-form {

  &.marketing-page-form {
    margin-top: 16px;
  }

  &__statusbar {
    margin-bottom: 24px;
  }

  .tile + .tile {
    margin-top: 32px;
  }
}