@import "src/styles/mixins";

.whitelist-page {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 300px);
}

.whitelist-page > .loader {
    display: block;
    margin: 0 auto;
}

.whitelist-section {
    padding-bottom: 80px;
}

.whitelist-section :is(.tile, .tile--secondary) + :is(.tile, .tile--secondary) {
    margin-top: 24px;
}

.whitelist-section .tile:not(.project-bio) p {
    margin-bottom: 0;
}

.whitelist-page .project-info {
    margin-bottom: 40px;
}

.whitelist-page .project-info__description {
    font-size: 14px;
}

.whitelist-page .kyc-approved-mark {
    padding: 20px 24px;
}

.whitelist-time .title {
    margin-bottom: 32px;
}

.whitelist-section .tile:not(.whitelist-time, .whitelist-form, .wrong-network-tile, .kyc-badge) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include gap(26px);
}

.whitelist-section .round-button {
    margin: 0;
}

.wrong-network-tile .title {
    margin-bottom: 24px;
}

:is(.whitelist-tier) .round-button {
    flex-shrink: 0;
}

.whitelist-tier .balance-item__icon {
    padding: 15px;
    background: rgba(255, 255, 255, 0.2);
    object-fit: contain;
    object-position: center;
}

@media (max-width: 1399px) {
    :is(.whitelist-tier) .round-button {
        width: 223px;
    }
}

.whitelist-nft-image {
    width: 100%;
}

.whitelist-nft-image img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 40px;
    border-radius: 32px;
}

@media (max-width: 1199px) {
    .whitelist-nft-image img {
        max-height: 600px;
    }
}
