.splash-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms linear 0ms;
  z-index: 1000;

  &__logo {
    width: 280px;
    animation-name: pulse;
    animation-duration: 1.1s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    svg {
      width: 100%;

      path {
        fill: var(--color-text-black);
      }
    }
  }
}

@keyframes pulse {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1.05);
  }
}
