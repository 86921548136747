/*!*.card.accordion span {*!*/
/*!*    display: block;*!*/
/*}*/

.card.accordion {
    font-size: 24px;
    line-height: 1.58;
    font-weight: 300;
    background: var(--color-text-white);
    border: 1px solid var(--color-medium-grey);
    border-radius: 24px;
    margin-bottom: 1rem;
    text-align: left;
}

.card.accordion .collapse {
    padding-top: 0;
    line-height: 150%;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.card.accordion .card-header {
    border: 0;
    padding: 0;
    border-radius: inherit;
}

.card.accordion .btn {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100% !important;
    text-decoration: none;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 400;
    color: var(--color-text-black);
    padding: 27px 24px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.card.accordion .btn span {
    transition: 0.3s linear;
}

.card.accordion .btn.active {
    font-weight: 600;
    color: var(--color-light-orange);
    background-color: var(--color-light-grey);
}

.card.accordion .btn svg path {
    fill: currentColor;
}

.card.accordion .btn.active span {
    transform: rotate(180deg);
}

.card.accordion .card-body {
    padding: 0 4.5rem 1.5rem 1.5rem;
    background-color: var(--color-light-grey);
    border-radius: inherit;
}
