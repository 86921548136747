@import "src/styles/mixins";

.account-staking {
    padding-top: 45px;

    &__stake-block {
      justify-content: space-between;

      .balance-item:not(:last-child) {
        margin-bottom: 32px;
      }
    }
}

.stake-allocation-section {
  padding-bottom: 48px;

  .title {
    margin-bottom: 24px;
    line-height: 1.6;
  }

  .bottom-description {
    font-size: 16px;
    line-height: 126%;
    color: var(--color-grey);
  }
}

.stake-block {
  &.row {
    margin-top: 24px;
  }

  .input-group {
    margin-bottom: 26px;
  }

  &__buttons {
    display: flex;
    width: 100%;
    margin: 0 0 5px;
    @include gap(16px);
  }

  .tooltip-wrapper {
    margin-left: 8px;
  }

  &__info {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    .info-list__item {
      &:not(:last-child) {
        margin-bottom: 32px;
      }

      .value {
        font-size: 40px;

        small {
          font-size: 20px;
        }
      }
    }
  }
}

.stake-form {

  .tab-content {
    flex: 1 0;
    padding: 40px;
    border-radius: 40px;
    border: 1px solid var(--color-medium-grey);
    margin-top: 24px;
  }

  &__input-icon {
    height: 48px;
  }

  .btn.round-button {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
  }

  .form-message {
    margin-bottom: 17px;
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    & > div {
      margin-left: 12px;
    }

    p {
      margin-bottom: 3px;
    }

    .round-button {
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      line-height: 13px;
      padding: 5px;
    }
  }
}

.account-staking .stake-form {
    height: 560px;
}

.stake-form .tab-content .tab-pane.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.stake-block-tab-unstake .stake-block__buttons {
    flex-wrap: wrap;
}

.stake-block-tab-unstake .stake-block__buttons .round-button {
    flex-basis: 100%;
}

.staking-stats {
    margin-top: 14px;

    &.info-list > div + div {
      margin-top: 0;
    }

    & > div {
      padding: 40px 0;
      border-bottom: 1px solid var(--color-separator);

      &:last-child {
        padding-bottom: 7px;
        border: none;
      }

      &:first-child {
        padding-top: 7px;
      }
    }
}
