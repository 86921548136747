.project-page {
    padding-top: 70px;
}

.project-page > .loader {
    display: block;
    margin: 0 auto;
}

.details-section .ellipse19 {
    top: 20px;
    left: 80%;
}

.details-section__warning-message {
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--color-text-red);
}

.swap-section {
    margin-top: 80px;
    padding-bottom: 50px;
}

.swap-section .ellipse18 {
    width: 220px;
    height: 220px;
    left: 3%;
    top: -50%;
}

.swap-section .tile {
    padding: 40px;
}

.project-page .whitelist-block {
    margin-top: 40px;
}

.project-page .whitelist-block p {
    max-width: 370px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;
}

.project-page .whitelist-block a:not(.round-button) {
    color: inherit;
}

.project-page .whitelist-block a:not(.round-button):hover {
    color: var(--color-text-white);
}

.project-page__wallet-info {
    margin-bottom: 40px;
}

@media (min-width: 1200px) {
    .project-page__wallet-info {
        padding-left: 44px;
        margin-bottom: 0;
    }
}

.whitelist-badge {
    display: inline-flex;
    padding: 18px 32px;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 32px;
    font-weight: 600;
    text-transform: uppercase;
}

.whitelist-badge.invalid,
.whitelist-badge.rejected,
.whitelist-badge.passed,
.whitelist-badge {
    background-color: var(--color-medium-grey);
}

.whitelist-badge.in_review,
.whitelist-badge.not_submitted {
    background: var(--color-light-grey);
}

.whitelist-badge .loader {
    --loader-size: 32px;
}

.whitelist-badge img {
    margin-right: 16px;
}

.tier-status {
    font-size: 20px;
    line-height: 1.26;
    letter-spacing: 1px;
    margin-bottom: 16px;
}

.tier-status span {
    font-weight: 700;
    font-style: italic;
}

.swap-form {
    border-radius: 24px;
    padding: 46px;
}

.swap-form .form-message {
    margin-bottom: 24px;
}

.swap-form .input-group {
    margin-bottom: 24px;
}

.swap-form .input-group img {
    width: 32px;
}

.swap-form__buttons {
    display: flex;
    width: 100%;
    margin: 0 0 5px;
}

.swap-form__buttons .btn {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
}

.swap-form__buttons .btn:not(:first-child) {
    margin-left: 26px;
}

.swap-form__buttons svg path {
    fill: var(--color-text-black);
}

.external-description__nft-image {
    width: 100%;
}

.external-description__nft-image img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 24px;
}

@media (max-width: 1199px) {
    .external-description__nft-image img {
        max-height: 600px;
    }
}

.external-description__nft-subtitle {
    font-size: 40px;
    font-weight: 600;
}

.claim-section {
    margin-top: 80px;
}

:is(.claim-section, .swap-section) .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 126%;
    margin-bottom: 24px;
}

@media (max-width: 1199px) {
    .swap-form {
        padding: 24px;
    }
}
