$header-height: 84px;

.page-heading {
  padding: calc(#{$header-height} + 40px) 32px 40px;
  margin-top: -#{$header-height};
  min-height: 362px;
  background-image: var(--page-heading-banner);
  background-size: calc(100% - 64px) 100%;
  background-position: center;
  background-repeat: no-repeat;

  &.large {
    min-height: 540px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-text-white);
  }

  h1.title {
    font-size: 112px;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 30px;
  }

  &__body {
    margin-top: 24px;
  }
}
