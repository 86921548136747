@import "src/styles/mixins";

.heat-row .color-strip {
  background: var(--color-light-orange);
  border-radius: 100px;
  height: 8px;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.color-strip__overlay {
  background: var(--color-light-grey);
  height: 100%;
}

.heat-row {
  .progress {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: unset;
    margin-top: -35px;
  }

  .item {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 6px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      flex-grow: 0.5;
      flex-shrink: 1;
      text-align: left;
      align-items: flex-start;
      padding-left: 0;

      .title {
        padding: 6px 16px;
        font-weight: 400;
        background-color: var(--color-light-grey);
        border: 3px solid var(--color-text-white);
        color: var(--color-text-black);
        border-radius: 100px;
        margin-top: 10px;
      }

      &.active .title {
        background-color: var(--color-light-orange);
        color: var(--color-text-white);
      }
    }

    &.active {
      .icon {
        border-color: var(--color-light-orange);
        color: var(--color-light-orange);
      }

      .description {
        background-color: var(--color-light-grey);
        border-color: var(--color-light-grey);
      }
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 126%;
      color: var(--color-grey);
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid var(--color-separator);
    color: var(--color-text-black);
    background-color: var(--color-text-white);

    svg {
      width: 32px;
      height: 32px;

      path {
        fill: currentColor;
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--color-grey);
  }

  .description {
    align-self: stretch;
    margin-top: 1rem;
    margin-bottom: 0;
    background-color: var(--color-text-white);
    border: 1px solid var(--color-separator);
    border-radius: 30px;
    padding: 16px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 126%;
    margin-bottom: 1rem;
  }
}
