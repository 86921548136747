@import "src/styles/mixins";

.project-info {
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  &__logo {
    width: 140px;
    height: 140px;
    margin-right: 24px;
    object-fit: cover;
    object-position: center;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    @include gap(8px)
  }

  &__name {
    font-size: 40px;
    line-height: 1.26;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__description {
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 300;
  }

  &__download-terms {
    display: block;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 1px;
    margin: 32px 0;
  }

  &__links {
    margin-top: 24px;
    display: flex;
    align-items: center;
    @include gap(12px);
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    background-color: var(--color-light-grey);
    border-radius: 20px;
    transition: .2s linear;
    color: var(--color-text-black);

    svg {
      width: 32px;
      height: 32px;

      path {
        fill: currentColor;
      }
    }

    &:hover {
      background-color: var(--color-medium-grey);
    }
  }
}

@media(max-width: 1199px) {
  .project-info__head {
    margin-bottom: 24px;
  }

  .project-info__logo {
    width: 100px;
    height: 100px;
    margin-right: 16px;
  }

  .project-info__name {
    font-size: 32px;
  }
}
