@import "src/styles/mixins";

.header {
  --header-text-color: var(--color-text-white);

  &.dark {
    --header-text-color: var(--color-text-black);
  }

  &.light {
    .round-button.transparent {
      --button-color: var(--color-text-white);
    }
  }

  &.container-fluid {
    padding-top: 32px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .navbar > * {
    flex-grow: 1;
    flex-basis: 20%;
  }

  .navbar-brand {

    a {
      color: var(--header-text-color);
    }

    svg {
      height: 50px;

      path {
        fill: currentColor;
      }
    }
  }

  .nav-link {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: var(--header-text-color);
    padding: 8px 8px;

    &:hover {
      color: var(--color-light-orange) !important;
    }

    &.active {
      border: none;
    }

    &.active:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      top: 100%;
      left: 0;
      background-color: var(--color-light-orange);
      border-radius: 2px;
    }
  }

  .round-button.header__nav-button {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navbar-collapse {
    @include gap(24px)
  }

  @media (max-width: 1199px) {
    .navbar .nav-link {
      padding: 0.5rem;
    }
  }
}

.nav-dropdown {
  &__menu.dropdown-menu {
    background-color: var(--color-text-white);
    color: var(--color-text-black);
    position: absolute;
    border: 1px solid var(--color-text-black);
    border-radius: 16px;
    min-width: 184px;
    top: calc(100% + 12px);
    z-index: 2;
  }

  &__item.dropdown-item {
    color: var(--color-text-black);
    padding: 0.65rem 1rem;
    font-weight: 500;
    letter-spacing: 1px;

    &:hover,
    &.active,
    &:active {
      background-color: inherit;
      color: var(--color-light-orange);
    }
  }
}

.network-switcher__dropdown .round-button.header__connect-wallet {
  display: contents;
}

.header__connect-wallet span {
  background-color: inherit;
  backdrop-filter: blur(40px);
  text-transform: none;
  display: block;
  padding: inherit;
  border-radius: inherit;

  &:hover {
    background-color: inherit;
  }
}
