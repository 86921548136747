.press-kit {
    margin-bottom: 40px;
}

.press-kit__list.row {
    margin-top: 48px;
}

.press-kit__item {
    height: 520px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
