.features-section .row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 2rem;
}

.features-item {
  background-color: rgba(11, 11, 15, 0.4);
  backdrop-filter: blur(200px);
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.15);
  border-radius: 24px;
  padding: 31px;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 400px;
}

.features-item__content {
  padding-left: 145px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

@media (max-width: 1399px) {
  .features-item {
    background-size: 100% 100%;
    padding: 24px;
    width: 350px;
    margin: auto;
  }

  .features-item__content {
    padding-left: 125px;
  }
}

.features-item__title {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.26;
}

.features-item__text {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.features-item--buy {
  background-image: url('../../../assets/features/buy-flame-bg.png');
}

.features-item--buy .features-item__content {
  display: flex;
  flex-direction: column;
}

.features-item--buy .round-button img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.features-item--buy .round-button.btn {
  padding: 11px 16px;
  text-align: left;
}

.features-item--buy .round-button + .round-button {
  margin-top: 12px;
}

.features-item--lock {
  background-image: url('../../../assets/features/lock-flame-bg.png');
}

.features-item--earn {
  background-image: url('../../../assets/features/earn-flame-bg.png');
}
