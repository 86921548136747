.lockup-content {
  .title {
    text-transform: uppercase;
  }

  .bottom-description {
    color: var(--color-grey);
  }

  .buy-zkst-dropdown .dropdown-toggle {
    line-height: 50px;
  }
}

.lockup-form {
  position: relative;

  &.hidden {
    display: none;
  }

  &--unlock {
    .loader {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn-close {
    position: absolute;
    right: var(--tile-padding);
    top: var(--tile-padding);
    width: 24px;
    height: 24px;
    background-size: 24px;
    z-index: 2;
  }

  &__tier {
    .loader {
      --loader-size: 24px;
    }

    .dynamic-image {
      width: 32px;
      height: 32px;
    }
  }

  &__input.form-control {
    font-size: 24px;
  }

  &__balances {
    margin: 20px 0;
  }

  .info-list .loader {
    --loader-size: 1.26em;
  }

  &__options {
    .loader {
      margin: 80px auto;
    }
  }

  & &__options-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  &__use-hiro {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border: 1px solid var(--color-separator);
    border-radius: 1rem;
    letter-spacing: 1px;

    img {
      margin-right: 12px;
    }

    .custom-switch {
      margin-left: auto;
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;

    .round-button {
      flex-basis: 100%;
      flex-grow: 1;
    }

    .round-button.transparent {
      background-color: transparent;
      border-color: transparent;
      backdrop-filter: none;
    }
  }
}

.locks-table-wrapper {
  .simple-table {
    &__head-row {
      border-bottom: 1px solid var(--color-separator);
    }

    th {
      line-height: 1.25;
      font-weight: 400;
      color: var(--color-grey);
      padding: 14px 0;
    }

    &__row:not(:last-child) {
      border-bottom: 1px solid var(--color-separator);
    }

    &__row.unlocked {
      color: var(--color-grey);
    }

    td {
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 1em;
      white-space: nowrap;
    }

    &__row:not(.unlocked) {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    td, th {
      &:not(:first-child):not(:last-child) {
        text-align: center;
      }

      &:not(:first-child) {
        padding-left: 7px;
      }

      &:not(:last-child) {
        padding-right: 7px;
      }


      @media (min-width: 1199px) and (max-width: 1399px) {
        &:not(:first-child) {
          padding-left: 6px;
        }

        &:not(:last-child) {
          padding-right: 6px;
        }
      }
    }
  }
}

.locks-table {

  &__action {

    .simple-table__cell {
      display: flex;
      justify-content: flex-end;
    }

    .round-button.small {
      line-height: 24px;
      padding: 14px 12px;
    }
  }

  &__period {
    .simple-table__cell {
      white-space: nowrap;
    }

    svg {
      margin-left: 4px;
    }
  }

  @media (min-width: 1199px) and (max-width: 1399px) {
    font-size: 14px;
  }
}

.lockup-option {
  position: relative;
  border: 1px solid var(--color-separator);
  border-radius: 16px;
  cursor: pointer;
  padding: 16px 14px;
  overflow: hidden;
  transition: 0.15s linear;

  &:hover:not(.active):not(.disabled) {
    box-shadow: 1px 1px 10px var(--color-light-orange);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active {
    border-color: var(--color-light-orange);
    background-color: rgba(255, 100, 57, 0.1);
  }

  input {
    display: none;
  }

  &__title {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;

    small {
      font-size: 0.675em;
    }
  }

  &__tooltip-trigger {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 2;

    svg path {
      fill: var(--color-grey);
    }
  }

  &__tooltip {
    min-width: 392px;
  }
}

.lockup-tooltip {
  &__title svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--color-grey);
    }
  }

  &__subtitle {
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  &__list {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-self: center;
      line-height: 1.26;
      letter-spacing: 0.5px;
      margin-bottom: 16px;

      span:first-child {
        color: var(--color-grey);
      }
    }
  }

  &__rewards-list {
    list-style-type: '– ';
    list-style-position: inside;
    padding-left: 0;
  }
}
