.mobile-screen {
  width: 100vw;
  min-height: 100vh;
  padding: 16px 0;

  &__header {
    margin-bottom: 32px;

    svg path {
      fill: var(--color-text-black);
    }
  }

  &__banner {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    margin-bottom: 24px;
    padding: 0 16px;

    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .caption {
      font-weight: 300;
    }
  }

  &__footer {
    padding: 0 16px;
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 12px;
      background-color: var(--color-light-grey);
      color: var(--color-text-black);

      svg {
        width: 32px;
        height: 32px;

        path {
          fill: currentColor;
        }
      }

      &:hover {
        background-color: var(--color-medium-grey);
      }
    }
  }
}