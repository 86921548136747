.portfolio-list {
    --portfolio-columns: 7fr repeat(2, 3fr) 2.5fr 3fr 4fr;
}

.portfolio-list .no-projects-tile.hidden {
    display: none;
}

.portfolio-list__body:empty + .no-projects-tile.hidden {
    display: flex;
}

.portfolio-list__body > .loader {
    display: block;
    margin: 35px auto 0;
}

.portfolio-list__header {
    list-style: none;
    padding: 16px 40px;
    margin: 0;
    display: grid;
    grid-gap: 18px;
    grid-template-columns: var(--portfolio-columns);
}

.portfolio-list__header li {
    display: flex;
    align-items: center;
    gap: 16px;
    line-height: 1.26;
    letter-spacing: 1px;
    color: var(--color-grey);
}

.portfolio-list .portfolio-item {
    margin-bottom: 24px;
}
