:root {
  --color-text-white: #FFFFFF;
  --color-text-black: #0B0909;
  --color-text-white-transparantize: rgba(255, 255, 255, 0.5);
  --color-background: var(--color-text-white);
  --color-light-orange: #FF6439;
  --color-text-red: #FE4646;
  --color-text-yellow: #FFBB55;
  --color-text-success: #02C88D;
  --color-text-violet: #C58BFF;
  --color-main-gradient-text: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);

  --color-light-grey: #F6F8FA;
  --color-medium-grey: #CCD2D9;
  --color-grey: #767779;
  --color-separator: var(--color-medium-grey);
  --color-separator-transparentize: rgba(65, 67, 71, 0.5);
  --container-max-width: 100%;

  --tile-padding: 40px;

  --base-font: 'Sora', sans-serif;
}

@media (min-width: 576px) {
  :root {
    --container-max-width: 540px;
  }
}

@media (min-width: 768px) {
  :root {
    --container-max-width: 720px;
  }
}

@media (min-width: 992px) {
  :root {
    --container-max-width: 960px;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  :root {
    --container-max-width: 1320px;
  }
}

:root {
  --container-sticky-max-width: calc(var(--container-max-width) + ((100vw - var(--container-max-width)) / 2) - 12px)
}
