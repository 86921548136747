.kyc-page {
  padding-bottom: 20px;

  .page-heading {
    margin-bottom: 80px;

    &__inner {
      margin-left: 40px;
    }
  }

  &__content .container {
    max-width: 848px;
  }

  &__caption {
    margin-bottom: 32px;
  }

  &__caption p {
    font-size: 32px;
    text-transform: uppercase;
  }

  &__requirements ul {
    margin-bottom: 0;
  }

  &__requirements li {
    font-size: 24px;
    line-height: 1.6;
  }

  &__requirements .kyc-badge__status p {
    font-size: 32px;
  }

  .kyc-badge {
    margin-top: 32px;
  }
}

