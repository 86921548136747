.about-us-page {
    .page-heading {
        margin-bottom: 80px;

        &__inner {
            margin-left: 40px;
        }
    }
}

.about-us-caption {
    margin-bottom: 80px;
}

.about-us-caption p {
    font-size: 40px;
    line-height: 1.6;
    max-width: 90%;
    text-transform: uppercase;
}
