.project-slide {
    width: 392px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

a.project-slide {
    text-decoration: none;
}

a.project-slide:hover {
    color: inherit;
}

a.project-slide:hover .project-slide__name {
    color: var(--color-light-orange);
}

.project-slide__head {
    margin-bottom: 16px;
}

.project-slide__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

@supports (gap: 8px) {
    .project-slide__tags {
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .project-slide__tags > * + * {
        margin-left: 8px;
    }
}

.project-slide__tags .project-tag {
    padding: 4px 8px;
}

.project-slide__name {
    font-size: 40px;
    font-weight: 600;
    transition: 0.15s linear;
}

.project-slide__subtitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 0;
}

.project-slide__name,
.project-slide__subtitle {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-slide__image {
    position: relative;
    width: 100%;
    height: 246px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-slide__logo {
    width: 140px;
    height: 140px;
    object-fit: cover;
    object-position: center;
}

.project-slide__logo--nft {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 2;
}

.project-slide__nft-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 16px;
}

.project-slide .project-slide__button.round-button {
    position: absolute;
    width: 54px;
    height: 54px;
    bottom: 24px;
    right: 24px;
    padding: 0;
    border: none;

    svg {
        width: 32px;
        height: 32px;
    }
}
