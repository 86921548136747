@import "src/styles/mixins";

.account-page {
    text-align: left;

    .page-heading {
        margin-bottom: 72px;
    }

    .page-heading__body {
        margin-top: 130px;
    }

    h3.title {
      font-size: 40px;
    }
}

.kyc-section .kyc-badge__status p {
    font-size: 32px;
}

.non-polygon-block, .non-polygon-block.col {
    max-width: 700px;
    margin: 50px auto;
    padding: 40px 25px;
}

@media (max-width: 1199px) {
    .account-nav .nav-link {
        font-size: 28px;
    }

    .stake-form {
        padding: 24px;
        border-radius: 50px;
    }

    .stake-block .nav-tabs .nav-link {
        margin-right: 16px;
        font-size: 24px;
    }
}
