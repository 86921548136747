.leaders-list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    padding: 5px 0 16px;
  }

  &__selections {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  &__selection-item {
    position: relative;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1px;
    padding: 0;
    transition: 0.15s linear;
    cursor: pointer;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      background-color: transparent;
      left: 0;
      bottom: -16px;
      transition: inherit;
    }

    &:hover {
      color: var(--color-light-orange);
    }

    &.active {
      font-weight: 700;

      &:after {
        background-color: var(--color-light-orange);
      }
    }

    & + & {
      margin-left: 32px;
    }
  }
}

.leaderboard-table-wrapper {
  overflow: hidden;
  border-radius: 32px;
  border: 1px solid var(--color-medium-grey);
}

.leaderboard-table .simple-table__head-row {
  background-color: var(--color-light-grey);
  height: 52px;
}

.leaderboard-table .simple-table__row {
  height: 90px;
  background: white;
}

.leaderboard-table :is(th, td) {
  line-height: 1.25;
  letter-spacing: 0.5px;
  vertical-align: middle;
  padding: 10px;
  font-weight: 500;
}

.leaderboard-table th {
  color: var(--color-grey);
  font-weight: 400;
}

.leaderboard-table tr {
  border-bottom: 1px solid var(--color-medium-grey);
}

.leaderboard-table__wallet {
  & > div {
    display: flex;
    align-items: center;

    & + div {
      margin-top: 8px;
    }
  }

  &-icon {
    margin-right: 5px;
  }
}
