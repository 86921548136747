.get-ready-badge.tile {
    border: none;
    background-color: transparent;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/get-incubated-bg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 40px;
}

.get-ready-badge .tile__main {
    font-size: 64px;
    line-height: 1.125;
    margin-bottom: 32px;
    text-align: center;
    color: var(--color-text-white);
    text-transform: uppercase;
    max-width: 70%;
}
