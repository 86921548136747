.approval-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

@supports (gap: 8px) {
    .approval-steps {
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .approval-steps > * + * {
        margin-left: 8px;
    }
}

.approval-steps__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 1px;
    color: var(--color-grey);
    opacity: 0.5;
}

.approval-steps__label:first-child {
    color: var(--color-text-black);
    opacity: 1;
}

.approval-steps__label.active {
    opacity: 1;
    font-weight: 600;
    color: var(--color-text-black);
}

.approval-steps__lines {
    display: flex;
    align-content: center;
    gap: 4px;
    width: 200px;
}

.approval-steps__line {
    width: 32px;
    height: 4px;
    border-radius: 100px;
    background-color: var(--color-medium-grey);
    transition: 0.2s linear;
}

.approval-steps__line.filled {
    flex-grow: 1;
    background-color: var(--color-light-orange);
}
