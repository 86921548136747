@import "src/styles/mixins";

.top-banner {
  min-height: 60vh;
  max-height: 75vh;
  max-width: 2000px;
  width: 100%;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 40px;

  .background {
    background-image: url('../../assets/top-banner/hero-banner.png');
    background-size: calc(100vw - 64px) 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: calc(100% + 84px);
    top: -84px;
  }
}

.top-banner .container {
  height: 100%;
}

.hero-row {
  display: flex;
  flex-direction: column;
  z-index: 100;
  height: 100%;
  justify-content: center;
  color: var(--color-text-white);

  [class*="col"] {
    z-index: 1;
  }

  & &__main {
    position: relative;
    padding-top: 25px;
    padding-bottom: 40px;
    margin-left: 40px;

    @media (max-height: 850px) {
      margin: 0 auto min(8vw, 6vh);
    }
  }

  .subtitle {
    font-size: 40px;
    line-height: 1.12;
  }

  .hero-buttons {
    margin-top: 24px;
    position: relative;
    display: flex;
    z-index: 2;
  }
}

.homepage .hero-row h1.title {
  position: relative;
  font-weight: 600;
  font-size: 80px;
  line-height: 112%;
  margin-bottom: 18px;
  text-transform: uppercase;
  z-index: 2;
}

.hero-buttons {
  &__countdown {
    display: inline-flex;
    align-items: center;
    padding: 8px 24px 8px 8px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  &__whitelist-date {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.26;
    letter-spacing: 1px;
  }
}

.hero-row .hero-buttons,
.hero-buttons__countdown,
.advantages-section__buttons {
  @include gap(16px)
}

.powered-by {
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 80px;

  &__text {
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 1px;
  }

  &__icons {
    svg {
      height: 45px;
      width: auto;
    }
  }
}

.homepage .features-section {
  margin-bottom: 115px;
  z-index: 2;
}

.coming-projects {
  padding-top: 85px;
  padding-bottom: 110px;
}

.partnerships .title:first-child {
  margin-bottom: 0;
  position: relative;
  z-index: 110;
}

.advantages-section {
  padding-top: 90px;
  padding-bottom: 110px;
}

.advantages-section__buttons {
  display: flex;
  margin-top: 38px;
}

.platform-advantages {
  padding-right: 10%;

  .advantages-item {
    margin-bottom: 16px;
    border-radius: 40px;
    padding: 40px;
    background-color: var(--color-light-grey);
    background-size: cover;
    background-position: center;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        background-image: url('../../assets/advantages-section/advantages-bg-#{$i}.png');
      }
    }

    &__title {
      font-size: 32px;
      margin-bottom: 16px;
      line-height: 1.12;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__text {
      line-height: 1.5;
      font-weight: 300;
      margin: 0;
    }
  }
}

.partnerships {
  padding-top: 120px;
  padding-bottom: 120px;
}

.partnerships .row {
  --bs-gutter-y: 2rem;
  --bs-gutter-x: 2rem;
  padding: 128px 40px 56px;
  background-image: url('../../assets/partners/partners-bg.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homepage .partnerships-col {
  max-height: 112px;
  display: flex;
  align-items: center;
}

.partnerships-item {
  width: 100%;
}

.get-ready-section {
  padding-top: 90px;
  padding-bottom: 90px;
}

.tiers-section {
  margin-top: 100px;
  padding-top: 36px;
  padding-bottom: 200px;
  background-image: url('../../assets/tiers/tiers-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tiers-section .title {
  margin-bottom: 56px;
  color: var(--color-text-white);
  font-size: 64px;
}

.faq-section {
  padding-top: 90px;
  padding-bottom: 76px;
}

.faq-column .title {
  font-style: normal;
  font-weight: 700;
  line-height: 126%;
  margin-bottom: 40px;
}

.faq-column .round-button {
  margin-top: 24px;
}
