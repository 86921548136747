@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');
@import "mixins";

body {
  margin: 0;
  font-family: var(--base-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  min-height: 100%;
  color: var(--color-text-black)
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

header {
  z-index: 3;
}

.page {
  flex: 1 0;
}

section {
  position: relative;
}

.background {
  pointer-events: none;
}

section .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

h1.title {
  font-size: 80px;
  font-weight: 700;
  line-height: 112%;
  text-align: left;
  margin-bottom: 70px;
  z-index: 1;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
h1.title {
    font-size: 70px;
  }
}

h2.title, h2.subtitle {
  font-size: 80px;
  font-weight: 700;
  line-height: 126%;
}

h3.title, h3.subtitle {
  font-size: 48px;
  font-weight: 700;
  line-height: 126%;
}

h4.title, h4.subtitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 126%;
  letter-spacing: -0.5px;
}

a {
  color: var(--color-text-black);
  transition: 0.2s linear;
}

a:hover {
  color: var(--color-light-orange);
  text-decoration: none;
}

.orange-text {
  color: var(--color-light-orange);
}

.grey-text {
  color: var(--color-grey);
}

.red-text {
  color: var(--color-text-red);
}

.success-text {
  color: var(--color-text-success);
}

.purple-text {
  color: var(--color-text-violet);
}

.white-transparent-text {
  color: var(--color-text-white-transparantize);
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text--orange {
  background-image: linear-gradient(94.07deg, #FF6D43 2.02%, #FE5145 27.83%, #FF9B38 55.36%, #FE4B61 78.24%);
}

.gradient-text--purple {
  background-image: var(--color-main-gradient-text);
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.text-simple {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.text-big {
  font-size: 20px;
  line-height: 1.26;
}

.text-wide {
  letter-spacing: 1px;
}

.bordered {
  border: 1px solid var(--color-separator);
}

.btn:focus {
  box-shadow: none !important;
}

.tile {
  padding: var(--tile-padding);
  background-color: var(--color-text-white);
  border-radius: 40px;
  color: var(--color-text-black);
  border: 1px solid var(--color-medium-grey);
}

.tile.tile--secondary {
  padding: var(--tile-padding);
  background-color: var(--color-light-grey);
  border: none;
  border-radius: 24px;
}

.tile__description {
  color: var(--color-grey);
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;
}

.tile__main {
  font-weight: 600;
  font-size: 40px;
  line-height: 126%;
}

.nav-tabs {
  &.nav {
    padding: 24px 40px;
    border-radius: 32px;
    border: none;
    @include gap(24px);
    display: inline-flex;
  }

  .nav-link {
    position: relative;
    text-transform: uppercase;
    padding: 8px 0;
    font-weight: 400;
    border: none;
    margin-bottom: 0;

    &:hover {
      border: none;
      margin-bottom: 0;

      &:not(.active) {
        color: var(--color-light-orange);
      }
    }

    &.active {
      font-weight: 600;
      border: none;
      background-color: transparent;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        height: 3px;
        border-radius: 20px;
        top: 100%;
        background-color: var(--color-light-orange);
      }
    }
  }

  &.dark {
    &.nav {
      background-color: var(--color-text-black);
    }

    .nav-link {
      color: var(--color-text-white);
    }
  }

  &.light {
    background-color: var(--color-light-grey);
    color: var(--color-text-black);
  }
}

.info-list {
  font-size: 16px;
  line-height: 1.26;
}

.info-list > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-list > div + div {
  margin-top: 16px;
}

.info-list .name {
  font-weight: 400;
  color: var(--color-grey);
}

.info-list .value {
  font-weight: 400;
  margin-bottom: 0;
}

.input-group {
  display: flex;
  align-items: center;
  background: var(--color-light-grey);
  border: 1px solid var(--color-medium-grey);
  backdrop-filter: blur(10px);
  padding: 16px;
  border-radius: 20px;
}

.input-group .round-button {
  padding: 6px 12px;
  border-radius: 8px;
}

.form-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 24px;
}

.form-row .form-group {
  margin-bottom: 0;
}

.form-control, .form-control:focus, .form-control:active {
  background: var(--color-light-grey);
  border: 1px solid var(--color-medium-grey);
  backdrop-filter: blur(10px);
  padding: 18px 16px;
  border-radius: 16px;
  box-shadow: none;
  color: var(--color-text-black);
}

.form-control[readonly] {
  background: rgba(255, 255, 255, 0.08);
  color: var(--color-grey);
}

.form-check, .form-check-input, .form-check-label {
  cursor: pointer;
}

.form-check .invalid-feedback:empty {
  display: none;
}

.form-check {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  background-color: var(--color-background);
  border: 2px solid var(--color-grey);
}

.form-check .form-check-input {
  float: none;
  flex-shrink: 0;
  margin: 0 15px 0 0;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0 0.25rem var(--color-text-white-transparantize);
}

.form-check-input:checked[type="checkbox"] {
  background-color: transparent;
  border-color: var(--color-light-orange);
}

.form-check-input:checked[type="checkbox"] {
  background-image: url('../assets/checkbox-checked.svg');
}

.form-check-input:checked[type="radio"] {
  background: var(--color-light-orange);
  background-clip: content-box;
  padding: 1px;
  border-color: var(--color-light-orange);
}

.form-label {
  color: var(--color-grey);
  font-size: 12px;
}

.form-label, .form-check-label {
  line-height: 1.26;
  letter-spacing: 1px;
}

.input-group-big {
  border-radius: 30px;
}

.input-group-big .form-control {
  font-size: 40px;
}

.input-group-prepend {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.input-group-prepend img {
  margin-right: 12px;
}

.input-group-prepend span {
  font-weight: 600;
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;
}

.input-group-append {
  margin-left: 16px !important;
}

.input-group .form-control {
  padding: 0;
  background: transparent;
  border: none;
  backdrop-filter: none;
  text-align: right;
  line-height: 126%;
  appearance: none;
  -moz-appearance: textfield;
}

@media (max-width: 1399px) {
  .input-group-prepend span {
    font-size: 16px;
  }

  .input-group .form-control {
    font-size: 30px;
  }
}

.input-group .form-control::-webkit-outer-spin-button,
.input-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .form-control::placeholder {
  color: var(--color-text-white-transparantize);
}

.input-group .form-control:focus {
  box-shadow: none;
}

.custom-switch {
  --switch-size: 26px;
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.custom-switch .custom-control-label {
  background-color: var(--color-background);
  border: 2px solid var(--color-background);
  height: calc(var(--switch-size) + 4px);
  width: calc(var(--switch-size) * 2 - 2px);
  border-radius: calc(var(--switch-size) * 2 - 2px);
  transition: 0.15s linear;
  cursor: pointer;
}

.custom-switch .custom-control-input {
  position: absolute;
  left: 2px;
  top: 2px;
  appearance: none;
  width: var(--switch-size);
  height: var(--switch-size);
  border-radius: 50%;
  background: #2D3449;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.01), 0px 3px 1px rgba(0, 0, 0, 0.03);
  transition: 0.15s linear;
  cursor: pointer;
}

.custom-switch .custom-control-input:checked {
  left: calc(100% - 2px - var(--switch-size));
}

.custom-switch .custom-control-input:checked ~ .custom-control-label {
  background-color: var(--color-text-violet);
  border-color: var(--color-text-violet);
}

.form-message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  transition: 0.15s linear;
  visibility: visible;
  opacity: 1;
}

.form-message.hidden {
  opacity: 0;
  visibility: hidden;
}

.form-message svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.form-message svg path {
  fill: var(--color-text-red);
}

.form-message--warning {
  color: var(--color-text-red);
}

.custom-alert {
  margin-bottom: 1rem;
  padding: 1.25rem;
  border-radius: 1rem;
}

.custom-alert-light {
  color: var(--color-grey);
  background-color: var(--color-background);
}

.custom-alert-dark {
  color: var(--color-text-white);
  background-color: var(--color-text-black);
}

.navbar-inner {
  background: transparent;
}

.bn-notify-custom {
  transition: .2s linear;
}

.bn-notify-notification-link:hover {
  color: var(--color-text-white);
}

.bn-notify-notification-link:hover .bn-notify-notification-info-message {
  color: var(--color-text-violet);
}
.custom-scroll {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: 6px;
  overflow-scrolling: touch;
  overflow: auto;
  overscroll-behavior: none none;
  -webkit-overflow-scrolling: touch;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  transition: .2s linear;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.custom-scroll::-webkit-scrollbar-button {
  display: none;
}

.back-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 24px;

  &:hover {
    .back-link__label {
      color: var(--color-light-orange);
    }
  }
}

.back-link .btn.round-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 12px;
  border-radius: 10px;
  border: none;
  --button-bg: var(--color-light-grey);
  background-color: var(--color-light-grey);

  &:hover {
    color: var(--button-color);
    background-color: var(--button-bg);
    border-color: var(--button-border);
  }
}

.back-link .round-button svg {
  margin: 0;
}

.back-link__label {
  color: var(--color-grey);
}

.copiable {
  cursor: copy;
  transition: 0.15s linear;
}

.copiable:hover {
  background-color: rgba(255,255,2555, 0.3);
}

.add-network-button {
  white-space: nowrap;
}
