.project-widget {
    transition: .2s linear;
    height: 100%;
    border: 1px solid transparent;
}

.project-widget__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.project-widget:hover {
    border-color: var(--project-main-color, var(--color-light-orange));
}

.project-widget .project-info {
    margin-bottom: 28px;
}

.project-widget .project-info__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em;
}

.project-widget__wrong-network-message {
    letter-spacing: 1px;
}

.project-widget .project-stats {
    margin-bottom: auto;
}

.project-widget__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

@supports (gap: 17px) {
    .project-widget__buttons {
        gap: 17px;
    }
}

@supports not (gap: 17px) {
    .project-widget__buttons > * + * {
        margin-left: 17px;
    }
}

.project-widget__buttons a {
    flex-grow: 1;
}

.project-widget__buttons .round-button {
    width: 100%;
    margin: 0;
}

@media (max-width: 1199px) {
    .project-widget.tile {
        padding: 32px;
    }

    .project-widget .project-info {
        margin-bottom: 24px;
    }

    .project-widget__buttons {
        margin-top: 24px;
    }
}
